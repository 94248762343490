import React from "react";
import { Container, Typography, Button } from "@mui/material";
import MenuAppBar from "../molecules/MenuAppBar";

const OccasionPage = () => {
  const handleOccasionClick = () => {
    window.location.href = "https://www.autoscout24.ch/de/s/seller-2011365";
  };

  return (
    <div className="App" style={{ backgroundColor: "black", minHeight: "100vh" }}>
      <MenuAppBar />
      <Typography variant="h5" align="right" gutterBottom style={{ color: "white", marginRight: "10px", marginTop: "50px" }}>
        Garage Tiefenwaag
      </Typography>
      <Typography variant="h5" align="right" gutterBottom style={{ color: "white", marginRight: "10px" }}>
        Tel: +41 56 222 75 62
      </Typography>

      <Container maxWidth="md">
        <Typography variant="h3" gutterBottom style={{ color: "#dbbf60", fontSize: "2.5rem" }}>
          Unsere Occasionen
        </Typography>
        <Typography variant="body1" align="left" gutterBottom style={{ color: "white", marginTop: "20px", fontSize: "1.4rem" }}>
          Wir haben laufend Occasions-Fahrzeuge, welche wir gerne{" "}
          <Button onClick={handleOccasionClick} style={{ color: "#fbc02d", fontSize: "1.4rem"}}>HIER</Button> veröffentlichen.
        </Typography>
      </Container>
      <div style={{ borderTop: "1px solid black", marginTop: "20px" }}></div>
    </div>
  );
};

export default OccasionPage;
