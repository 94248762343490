import React from "react";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";

interface TeamMemberCardProps {
  name: string;
  imageUrl: string;
  education?: string;
}

const TeamMemberCard: React.FC<TeamMemberCardProps> = ({ name, imageUrl, education }) => {
  return (
    <Card>
      <CardMedia
        component="img"
        height="400"
        image={imageUrl}
        alt={name}
      />
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          {name}
        </Typography>
        {education && (
          <Typography variant="body1" color="textSecondary">
            {education}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default TeamMemberCard;
