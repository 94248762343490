import React, { useState, useEffect } from "react";
import MenuAppBar from "../molecules/MenuAppBar";
import { Container, Box, Typography } from "@mui/material";

const Slideshow: React.FC<{ images: string[] }> = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "calc(100vh - 80px)",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          transform: `translateX(-${currentIndex * 100}%)`,
          transition: "transform 1.7s ease-in-out",
        }}
      >
        {images.map((image, index) => (
          <div
            key={index}
            style={{
              flex: "0 0 100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={image}
              alt="Slideshow"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                width: "auto",
                height: "auto",
                objectFit: "contain",
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const HomePage: React.FC = () => {
  const images: string[] = [
    require("../Fotos/Team.jpeg"),
    require("../Fotos/Garage1.jpeg"),
    require("../Fotos/Garage2.jpeg"),
    require("../Fotos/Innen_Garage1.jpg"),
    require("../Fotos/Innen_Garage2.jpg"),
  ];

  return (
    <div className="App" style={{ backgroundColor: "black", minHeight: "100vh" }}>
      <MenuAppBar />
      <Box display="flex" justifyContent="center" alignItems="center" style={{ marginTop: "80px" }}>
        <Typography variant="h2" gutterBottom style={{ color: "#dbbf60" }}>
          Herzlich Willkommen
    </Typography>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography variant="body1" gutterBottom style={{ color: "white", textAlign: "center", maxWidth: "600px", fontSize: "1.2rem" }}>
          Wir sind eine kleine markenoffene Garage in Ehrendingen AG. <br />Mit Herzblut und Leidenschaft betreuen wir Fahrzeuge aller Hersteller und Jahrgänge.
        </Typography>

      </Box>

      <Container
        maxWidth="md"
        sx={{
          display: "flex",
          height: "calc(100vh - 100px)",
          overflow: "hidden",
          marginTop: "100px",
        }}
      >
        <Box sx={{ width: "100%", height: "100%" }}>
          <Slideshow images={images} />
        </Box>
      </Container>
    </div>

  );
};

export default HomePage;
