import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import TeamMemberCard from "../molecules/TeamMemberCard";
import MenuAppBar from "../molecules/MenuAppBar";

const TeamPage = () => {
    const teamMembers = [
        { name: "Roger Meier", imageUrl: require("../Fotos/Roger1.jpg"), education: "Geschäftsinhaber" },
        { name: "Stefan Zbinden", imageUrl: require("../Fotos/Stefan2.jpg"), education: "Geschäftsinhaber" },
        { name: "Simon Vollenweider", imageUrl: require("../Fotos/Simon1.jpg"), education: "Mitarbeiter" },
        { name: "Yannic Zbinden", imageUrl: require("../Fotos/Janic1.jpg"), education: "Lehrling" },
    ];

    return (
        <div style={{ backgroundColor: "black", minHeight: "100vh" }}>
            <MenuAppBar />
            <Typography variant="h5" align="right" gutterBottom style={{ color: "white", marginRight: "10px", marginTop: "50px" }}>
                Garage Tiefenwaag
            </Typography>
            <Typography variant="h5" align="right" gutterBottom style={{ color: "white", marginRight: "10px" }}>
                Tel: +41 56 222 75 62
            </Typography>
            <Container sx={{ marginTop: 5, paddingBottom: "50px" }}>
                <Typography variant="h3" gutterBottom style={{ color: "#dbbf60", fontSize: "2.5rem", marginBottom: "20px" }}>
                    Unser Team
                </Typography>
                <Grid container spacing={3}>
                    {teamMembers.map((member, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <TeamMemberCard name={member.name} imageUrl={member.imageUrl} education={member.education} />
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <div style={{ borderTop: "1px solid black", marginTop: "20px" }}></div>
        </div>
    );
};

export default TeamPage;
