import { Container } from '@mui/material';
import React from 'react';
import backgroundImage from '../Fotos/Streifen_Footer.png';

const backgroundImgStyle: React.CSSProperties = {
  width: '100%',
  height: 'auto',
  display: 'block',
  margin: 'auto',
};

const appBarStyle: React.CSSProperties = {
  backgroundColor: 'black',
};

const Footer: React.FC = () => {
  return (
    <footer className="footer" style={appBarStyle}>
      <Container style={{ maxWidth: '1500px' }}>
          <img src={backgroundImage} alt="Background" style={backgroundImgStyle} />
        </Container>
    </footer>
  );
}

export default Footer;
