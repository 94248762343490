import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import backgroundImage from "../Fotos/Streifen_Header.png";
import { Box, Button, Menu } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";

function MenuAppBar() {
  const [activeButton] = useState("");
  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const JobsPageClick = () => {
    navigate("/jobs");
  };

  const OccasionenPageClick = () => {
    navigate("/occasionen");
  };

  const DienstleistungenPageClick = () => {
    navigate("/dienstleistungen");
  };

  const FotoGaleriePageClick = () => {
    navigate("/team");
  };

  const KontaktPageClick = () => {
    navigate("/kontakt");
  };

  const buttonStyle = {
    my: 1,
    mx: 2,
    color: "#dbbf60",
    borderColor: "#dbbf60",
    "&:hover": {
      borderColor: "gray",
    },
  };
  const backgroundImgStyle: React.CSSProperties = {
    width: '100%',
    height: 'auto',
    display: 'block',
    margin: 'auto',
  };

  const appBarStyle: React.CSSProperties = {
    backgroundColor: 'black',
  };


  return (
    <AppBar position="static" style={appBarStyle}>
      <Toolbar>
        <Container style={{ maxWidth: '1500px' }}>
          <Link to="/">
            <img src={backgroundImage} alt="Background" style={backgroundImgStyle} />
          </Link>




          {/* Wenn Webseite klein ist */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" }, marginTop: "10px" }} >
            <Button
              size="large"
              variant="outlined"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </Button>

            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
                border: "1px solid",
              }}
            >
              <Button
                onClick={DienstleistungenPageClick}
                variant="outlined"
                sx={{
                  ...buttonStyle,
                  my: 2,
                  display: "block",
                  marginY: "8px",
                  width: "85%"
                }}
              >
                Dienstleistungen
                </Button>
                <Button
                onClick={OccasionenPageClick}
                variant="outlined"
                sx={{
                  ...buttonStyle,
                  my: 2,
                  display: "block",
                  marginY: "8px",
                  width: "85%"
                }}
              >
                Occasionen
                </Button>
                <Button
                onClick={FotoGaleriePageClick}
                variant="outlined"
                sx={{
                  ...buttonStyle,
                  my: 2,
                  display: "block",
                  marginY: "8px",
                  width: "85%"
                }}
              >
                Unser Team
                </Button>
              <Button
                onClick={JobsPageClick}
                variant="outlined"
                sx={{
                  ...buttonStyle,
                  my: 2,
                  display: "block",
                  marginY: "8px",
                  width: "85%"
                }}
              >
                Jobs
                </Button>
              <Button
                onClick={KontaktPageClick}
                variant="outlined"
                sx={{
                  ...buttonStyle,
                  my: 2,
                  display: "block",
                  marginY: "8px",
                  width: "85%"
                }}
              >
                Kontakt
                </Button>
            </Menu>
          </Box>


          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button
              onClick={DienstleistungenPageClick}
              variant="outlined"
              sx={{
                ...buttonStyle,
                width: "auto",
              }}
            >
              Dienstleistungen
              </Button>
              <Button
              onClick={OccasionenPageClick}
              variant="outlined"
              sx={{
                ...buttonStyle,
                width: "auto",
              }}
            >
              Occasionen
              </Button>
              <Button
              onClick={FotoGaleriePageClick}
              variant="outlined"
              sx={{
                ...buttonStyle,
                width: "auto",
              }}
            >
              Unser Team
              </Button>
            <Button
              onClick={JobsPageClick}
              variant="outlined"
              sx={{
                ...buttonStyle,
                width: "auto",
              }}
            >
              Jobs
              </Button>
            <Button
              onClick={KontaktPageClick}
              variant="outlined"
              sx={{
                ...buttonStyle,
                width: "auto",
              }}
            >
              Kontakt
              </Button>
          </Box>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default MenuAppBar;
