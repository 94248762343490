import Typography from "@mui/material/Typography";
import React from "react";
import { Link } from "react-router-dom";
import GarageImage from "../Fotos/Garage3.jpeg";
import MenuAppBar from "../molecules/MenuAppBar";

const NotFoundPage: React.FC = () => {
  return (
    <div style={{ backgroundColor: "black", minHeight: "100vh" }}>
      <MenuAppBar />
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Typography variant="h1" style={{ fontSize: "3rem", color: "white", marginTop: "50px" }}>Ups!</Typography>
        <Typography variant="h2" style={{ fontSize: "2rem", color: "white", textAlign: "center" }}>
          Die von Ihnen gesuchte Seite konnte nicht gefunden werden.
        </Typography>
        <img
          src={GarageImage}
          alt="Garage"
          style={{ maxWidth: "40%", height: "auto", marginTop: "20px" }}
        />

        <Link
          to="/"
          style={{
            marginTop: "20px",
            textDecoration: "none",
            color: "white",
            fontSize: "1.2rem",
            transition: "color 0.3s ease",
          }}
        >
          Zurück zur Startseite
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
