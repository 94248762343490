import React from "react";
import { Container, Typography, Button, Card, CardContent, CardActions } from "@mui/material";
import MenuAppBar from "../molecules/MenuAppBar";

const JobPage = () => {
  return (
    <div style={{ backgroundColor: "black", minHeight: "100vh" }}>
      <MenuAppBar />
      <Typography variant="h5" align="right" gutterBottom style={{ color: "white", marginRight: "10px", marginTop: "50px" }}>
        Garage Tiefenwaag
      </Typography>
      <Typography variant="h5" align="right" gutterBottom style={{ color: "white", marginRight: "10px" }}>
        Tel: +41 56 222 75 62
      </Typography>
      <Container maxWidth="md">
        <Typography variant="h3" gutterBottom style={{ color: "#dbbf60", fontSize: "2.5rem" }}>
        </Typography>
        
        <Typography variant="body1" align="left" gutterBottom style={{ color: "white", marginTop: "20px", fontSize: "1.4rem" }}>
          Hier finden Sie eine Übersicht unserer Stellenangebote. Gerne nehmen wir Ihre Bewerbungsunterlagen jederzeit entgegen: <a href="mailto:job@garagetiefenwaag.ch" style={{ color: "#dbbf60", textDecoration: "none" }}>job@garagetiefenwaag.ch</a> < br />Auch Blindbewerbungen sind erwünscht.
        </Typography>
        
        <Typography variant="body1" align="left" gutterBottom style={{ color: "white", fontSize: "1.4rem" }}>
          Für Fragen stehen wir gerne auch telefonisch zu Ihrer Verfügung unter der Nummer <span style={{ color: "#dbbf60" }}>+41 56 222 75 62</span>.
        </Typography>
        
        <Typography variant="h5" align="left" gutterBottom style={{ color: "#dbbf60", marginTop: "30px", fontSize: "1.6rem" }}>
          Zur Zeit sind keine Stellen zu besetzen
        </Typography>
      </Container>
      <div style={{ borderTop: "1px solid black", marginTop: "20px" }}></div>
    </div>
  );
};

export default JobPage;
