import React from 'react';
import { Container, Typography } from '@mui/material';
import MenuAppBar from '../molecules/MenuAppBar';
import RogerImage from '../Fotos/Roger1.jpg'; 
import StefanImage from '../Fotos/Stefan2.jpg';

const ContactPage: React.FC = () => {
  return (
    <div style={{ backgroundColor: "black", minHeight: "100vh" }}>
      <MenuAppBar />
      <Typography variant="h5" align="right" gutterBottom style={{ color: "#dbbf60", marginRight: "10px", marginTop: "50px", textAlign: "right" }}>
        Garage Tiefenwaag
      </Typography>
      <div style={{ color: 'white', textAlign: 'right', marginRight: '10px' }}>
        <p style={{ fontSize: '16px', marginTop: '5px', color: 'white', textAlign: 'right' }}>
          Telligraben 8<br />
          CH-5420 Ehrendingen<br />
          Tel: +41 56 222 75 62<br />
          <a href="mailto:info@garagetiefenwaag.ch" style={{ color: 'white', textDecoration: 'none' }}>
            info@garagetiefenwaag.ch
          </a>
        </p>
        <br />
        <div style={{ color: 'white', textAlign: 'right', marginRight: '10px' }}>
          <p style={{ fontSize: '16px', marginTop: '5px', color: 'white', textAlign: 'right' }}>
            <Typography component="span" variant="body1" style={{ fontWeight: 'bold', textAlign: 'center' }}>
              Montag - Freitag
            </Typography>
            <br />
            07:15 Uhr - 12:00 Uhr<br />
            13:15 Uhr - 17:30 Uhr<br />
            <Typography component="span" variant="body1" style={{ fontWeight: 'bold', textAlign: 'center' }}>
              Samstag & Sonntag Ruhetag
            </Typography>
          </p>
        </div>
      </div>

      <Container maxWidth="md">
        <Typography variant="h3" gutterBottom style={{ color: "#dbbf60", fontSize: "2.5rem" }}>
          Ihre Ansprechpartner
      </Typography>

        <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px', color: 'white', marginLeft: '10px' }}>
          <img src={RogerImage} alt="Roger Meier" style={{ width: '200px', height: 'auto', marginRight: '20px' }} />
          <div>
            <h3>Roger Meier</h3>
            <p style={{ fontSize: '16px', marginTop: '5px' }}>Geschäftsinhaber<br />Automechaniker & Administration</p>
          </div>
        </div>

        <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px', color: 'white', marginLeft: '10px' }}>
          <img src={StefanImage} alt="Stefan Ronaldo" style={{ width: '200px', height: 'auto', marginRight: '20px' }} />
          <div>
            <h3>Stefan Zbinden</h3>
            <p style={{ fontSize: '16px', marginTop: '5px' }}>Geschäftsinhaber<br />Automechaniker & Lehrlingsausbildner</p>
          </div>
        </div>

        <iframe
          title="Google Maps"
          width="98%"
          height="500"
          src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Garage Tiefenwaag&amp;t=h&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          style={{
            border: 0,
            marginLeft: "17px",
            marginBottom: "100px",
            marginTop: "100px",
            borderRadius: "20px",
          }}
          loading="lazy"
        ></iframe>
      </Container>
    </div>
  );
}

export default ContactPage;
