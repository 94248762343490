import React from "react";
import { Container, Typography, Card, CardContent } from "@mui/material";
import MenuAppBar from "../molecules/MenuAppBar";

const DienstleistungenPage = () => {
  return (
    <div style={{ backgroundColor: "black", minHeight: "100vh" }}>
      <MenuAppBar />
      <Typography variant="h5" align="right" gutterBottom style={{ color: "white", marginRight: "10px", marginTop: "50px" }}>
        Garage Tiefenwaag
      </Typography>
      <Typography variant="h5" align="right" gutterBottom style={{ color: "white", marginRight: "10px" }}>
        Tel: +41 56 222 75 62
      </Typography>
      <Container maxWidth="md">
        <Typography variant="h3" gutterBottom style={{ color: "#dbbf60", fontSize: "2.5rem" }}>
          Unsere Dienstleistungen
        </Typography>
        <ul style={{ paddingLeft: "20px", color: "white", fontSize: "1.4rem" }}>
          <li>Wartungsarbeiten an allen Fahrzeugmarken nach Herstellerangaben</li>
          <li>Fachgerechte Reparaturen</li>
          <li>Vorführungen Motor-Fahrzeugkontrolle</li>
          <li>Reifenservice inkl. Lagerung</li>
          <li>Klimaservice</li>
          <li>Occasion Kaufberatung</li>
          <li>Scheibenreparaturen</li>
          <li>Karosseriearbeiten</li>
          <li>Diesel/Benzin Abgaswartungen</li>
          <li>Bremsprüfstand</li>
          <li>Fahrzeugreinigung</li>
          <li>Campingfahrzeuge-Service und Erweiterungsumbauten</li>
          <li>Einbau und Verkauf von Zubehör</li>
        </ul>
        <Typography variant="h5" align="left" gutterBottom style={{ color: "#dbbf60", marginTop: "30px", fontSize: "1.6rem" }}>
          ERSATZWAGEN stellen wir kostenlos zur Verfügung
        </Typography>
      </Container>
      <div style={{ borderTop: "1px solid black", marginTop: "20px" }}></div>
    </div>
  );
};

export default DienstleistungenPage;
